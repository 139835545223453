import React, { PropsWithChildren } from "react";
import { CssBaseline, CssVarsProvider } from "@mui/joy";
import { newTheme } from "./theme";

export default function StylesConfig(props: PropsWithChildren<{}>) {
  return (
    <CssVarsProvider theme={newTheme}>
      <CssBaseline />
      {props.children}
    </CssVarsProvider>
  );
}
