import newStyled from "@emotion/styled";
import { Input, Textarea, Button } from "@mui/joy";

export const StyledInput = newStyled(Input)`
  margin-bottom: 1rem;
`;

export const StyledTextarea = newStyled(Textarea)`
  margin-bottom: 1rem;
`;

export const StyledButton = newStyled(Button)`
  width: 100%;
  &:disabled {
    cursor: not-allowed;
  }
`;
