import React from "react";
import { Iframe, LocationMapWrapper } from "./styles";
import { SectionTitle } from "../SectionTitle";
import { Box, Skeleton } from "@mui/joy";

export const LocationMap = () => {
  return (
    <LocationMapWrapper
      className="content-wrapper"
      gap={{ xs: "20px", md: "30px", lg: "40px" }}
      id="our_location_map"
    >
      <SectionTitle>Find us on Google Maps</SectionTitle>
      <Box width={"100%"} height={"380px"} position={"relative"}>
        <Skeleton width={"100%"} height={"100%"} style={{ zIndex: "-1" }} />
        <Iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.9474485604333!2d36.83414407502399!3d-1.1971431355352946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b3231a59f88c995%3A0xf1e0ee6803297561!2sGreatDeal%20Solar%20Solutions!5e0!3m2!1sen!2ske!4v1694063333571!5m2!1sen!2ske"
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          // style={{ height, width }}
        ></Iframe>
      </Box>
    </LocationMapWrapper>
  );
};
