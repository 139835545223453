import newStyled from "@emotion/styled";
import { MenuButton } from "@mui/base/MenuButton";
import { MenuItem } from "@mui/base/MenuItem";
import { Menu } from "@mui/base/Menu";
import { Box, Button, Stack, Typography } from "@mui/joy";
import navAdvSectionBg from "@media/images/nav-adv-section-bg.png";

export const DesktopNavWrapper = newStyled(Stack)`
font-size: 18px;

@media only screen and (max-width: 620px) {
  display: none;
}
`;

export const NavItemButton = newStyled(MenuButton)`
font-size: 18px;
border: none;
background-color: transparent;
color: var(--dark-color);
cursor: pointer;
height: var(--header-height);
transition: background-color 0.5s;
& > div {
 border-radius: 24px;
 padding: 8px 16px;
 background-color: transparent;
 color: var(--nav-item-color);
 font-weight: 600;
 &:hover {
    background-color: var(--nav-item-hover-color);
  }
}

&[aria-expanded="true"] > div {
  background-color: var(--nav-item-hover-color);
}
`;

export const NavMenuWrapper = newStyled(Menu)`
height: 200px;
background-color: var(--white-color);
width: 720px;
border-radius: 10px;
overflow: hidden;
margin-top: 8px;
margin-right: 8px;
box-shadow: 0 8px 8px rgba(48,48,48,0.08);
position: relative;
z-index: 200;
color: var(--text-color);

& ul {  
  list-style-type: none;
}

@media only screen and (min-width: 621px) and (max-width: 768px) {
  width: 520px;
}

@media only screen and (max-width: 620px) {
  display: none;
}
`;

export const NavMenuAdvert = newStyled(Box)`
width: 200px;
height: 200px;
background-image: url(${navAdvSectionBg});
position: relative;

@media only screen and (max-width: 768px) {
  display: none;
}
`;

export const NavMenuAdvertOverlay = newStyled(Stack)`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
padding: 24px;
background: linear-gradient(
  225deg, 
  rgba(255, 255, 255, 1) 2%, 
  rgba(255, 255, 255, 1) 51%, 
  rgba(255, 255, 255, 0.9) 64%,  
  rgba(255, 255, 255, 0.8) 81%, 
  rgba(255, 255, 255, 0.4) 100%
);
& h1 {
  font-size: 16px;
  color: var(--secondary-color);
}
  & p {
  width: 175px;
  }
`;

export const NavMenuTabsWrapper = newStyled(Stack)`
padding: 20px;
width: 480px;
`;

export const NavMenuLinksWrapper = newStyled(Stack)`
display: flex;
flex-wrap: wrap;
height: 120px;
overflow: auto;
`;

export const TabButtonWrapper = newStyled(Button)`
font-size: 12px;
text-transform: uppercase;
`;

export const NavMenu = newStyled(MenuItem)`
min-width:80px;
height: 40px;
font-size: 16px;
color: var(--text-color);
cursor: pointer;
box-shadow: 0 2px 4px rgba(48,48,48,0.08);
border-radius: 8px;
border: 1px solid rgba(48,48,48,0.08);
`;

export const NavMenuTitle = newStyled(Typography)`
position: absolute;
line-height: 20px;
right: 10px;
bottom: 5px;
text-transform: uppercase;
color: var(--dark-color-transparency-5);
`;
