exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-company-index-tsx": () => import("./../../../src/pages/about/company/index.tsx" /* webpackChunkName: "component---src-pages-about-company-index-tsx" */),
  "component---src-pages-about-contacts-index-tsx": () => import("./../../../src/pages/about/contacts/index.tsx" /* webpackChunkName: "component---src-pages-about-contacts-index-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-lithium-valley-battery-index-tsx": () => import("./../../../src/pages/products/lithium-valley-battery/index.tsx" /* webpackChunkName: "component---src-pages-products-lithium-valley-battery-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-installation-index-tsx": () => import("./../../../src/pages/services/installation/index.tsx" /* webpackChunkName: "component---src-pages-services-installation-index-tsx" */),
  "component---src-pages-services-maintenance-index-tsx": () => import("./../../../src/pages/services/maintenance/index.tsx" /* webpackChunkName: "component---src-pages-services-maintenance-index-tsx" */),
  "component---src-pages-solutions-home-owners-index-tsx": () => import("./../../../src/pages/solutions/home-owners/index.tsx" /* webpackChunkName: "component---src-pages-solutions-home-owners-index-tsx" */),
  "component---src-pages-solutions-index-tsx": () => import("./../../../src/pages/solutions/index.tsx" /* webpackChunkName: "component---src-pages-solutions-index-tsx" */),
  "component---src-templates-product-details-index-tsx": () => import("./../../../src/templates/product-details/index.tsx" /* webpackChunkName: "component---src-templates-product-details-index-tsx" */),
  "component---src-templates-shop-index-tsx": () => import("./../../../src/templates/shop/index.tsx" /* webpackChunkName: "component---src-templates-shop-index-tsx" */)
}

