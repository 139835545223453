import React, { useEffect } from "react";
import StylesConfig from "./src/styles/StylesConfig";
import PageLayout from "./src/components/PageLayout";
import {
  PageElementContext,
  PageElementContextProvider,
} from "./src/context/PageElementContext";

export const wrapPageElement = ({ element, props }) => {
  const hideHeader =
    props.location.pathname === "/" ||
    props.location.pathname.includes("/products/");

  useEffect(() => {
    const chatWidgetIframe: HTMLIFrameElement | null = document.querySelector(
      ".open[title='chat widget']"
    );

    if (chatWidgetIframe) {
      chatWidgetIframe.style.boxShadow =
        "0 0 16px 0 var(--dark-color-transparency-1) !important";
    }
  }, []);

  return (
    <StylesConfig>
      <PageLayout {...props} hideHeader={hideHeader}>
        {element}
      </PageLayout>
    </StylesConfig>
  );
};
