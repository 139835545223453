import React, { PropsWithChildren, useMemo, useState } from "react";
import { Dropdown } from "@mui/base/Dropdown";
import { Stack, Typography, Box } from "@mui/joy";
import { NavLink } from "@types";
import { TabButton } from "./TabButton";

import {
  NavItemButton,
  NavMenuWrapper,
  NavMenu,
  NavMenuAdvert,
  NavMenuAdvertOverlay,
  NavMenuTabsWrapper,
  NavMenuLinksWrapper,
  NavMenuTitle,
} from "./styles";
import { NavMenuLink } from "../NavMenuLink";

export type TabItemType = {
  label: string;
  links: NavLink[];
};

export type TabsType = {
  [tab: string]: TabItemType;
};

type NavItemProps = {
  tabs: TabsType;
};

export const NavItem: React.FC<PropsWithChildren<NavItemProps>> = ({
  children,
  tabs,
}) => {
  const [tab, setTab] = useState(Object.keys(tabs)[0]);

  const tabsMap = useMemo(() => {
    const map = new Map<string, NavLink[]>();

    Object.keys(tabs).forEach((key) => {
      map.set(key, tabs[key].links);
    });

    return map;
  }, [tabs]);

  return (
    <Dropdown>
      <NavItemButton>
        <Box>{children}</Box>
      </NavItemButton>
      <NavMenuWrapper>
        <NavMenuTitle level="h4" fontWeight={"300"}>
          {children}
        </NavMenuTitle>
        <Stack direction={"row"} gap={"10px"}>
          <NavMenuAdvert>
            <NavMenuAdvertOverlay gap={"18px"}>
              <Typography component={"h1"}>We've got you covered</Typography>
              <Typography component={"p"}>
                Talk to us on everything to do with solar power installation and
                maintenance
              </Typography>
            </NavMenuAdvertOverlay>
          </NavMenuAdvert>
          <NavMenuTabsWrapper gap={"10px"}>
            <Stack direction={"row"} gap={"10px"} fontSize={"16px"}>
              {Object.keys(tabs).map((key) => (
                <TabButton
                  key={key}
                  selectedTab={tab}
                  setTab={setTab}
                  tabKey={key}
                >
                  {tabs[key].label}
                </TabButton>
              ))}
            </Stack>

            <NavMenuLinksWrapper
              direction={"row"}
              flexWrap={"wrap"}
              gap={"0px 8px"}
            >
              {tabsMap.get(tab)?.map((item) => (
                <NavMenu
                  style={{
                    padding: 0,
                  }}
                  key={item.label}
                  component="div"
                >
                  <NavMenuLink nav={item} />
                </NavMenu>
              ))}
            </NavMenuLinksWrapper>
          </NavMenuTabsWrapper>
        </Stack>
      </NavMenuWrapper>
    </Dropdown>
  );
};
