import * as React from "react";
import { FormControl } from "@mui/joy";
import { StyledButton, StyledInput, StyledTextarea } from "./styles";
import useEmailApi from "@hooks/useEmailApi";
import { FormFeedback } from "../../FormFeedback";

export const MessageForm = () => {
  const [name, setName] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [message, setMessage] = React.useState("");

  const { feedback, loading, clearFeedBack, submitMessage } = useEmailApi({
    name,
    contact,
    message,
  });

  return (
    <form>
      <FormFeedback feedback={feedback} clearFeedBack={clearFeedBack} />
      <FormControl>
        <StyledInput
          placeholder="Your name"
          onChange={(change) => setName(change.target.value)}
          required
        />
        <StyledInput
          placeholder="Your contact - email or mobile number"
          onChange={(change) => setContact(change.target.value)}
          required
        />
        <StyledTextarea
          minRows={4}
          placeholder="Your message"
          onChange={(change) => setMessage(change.target.value)}
          required
        />
        <StyledButton
          disabled={!name || !contact || !message}
          loading={loading}
          onClick={submitMessage}
          type="submit"
        >
          Send message
        </StyledButton>
      </FormControl>
    </form>
  );
};
