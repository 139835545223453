import React, { FC, PropsWithChildren } from "react";
import { Typography } from "@mui/joy";

export const SectionTitle: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography fontSize={{ xs: "1.5rem", md: "1.8rem", lg: "2rem" }}>
      {children}
    </Typography>
  );
};
