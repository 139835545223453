import newStyled from "@emotion/styled";
import { Stack } from "@mui/joy";

export const LocationMapWrapper = newStyled(Stack)`
margin: 50px auto;
margin-bottom: 200px;
`

export const Iframe = newStyled.iframe`
  border: none;
  width: 100%;
  height: 380px;
  background-color: var(--text-color-transparency-1);
`;
