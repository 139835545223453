
const keyValueMap: Record<string, string> = {
  A: "x6a8",
  B: "x6b9",
  C: "x7c0",
  D: "x7d1",
  E: "x8e2",
  F: "x8f3",
  G: "x9g4",
  H: "x9h5",
  I: "xai6",
  J: "xaj7",
  K: "xak8",
  L: "xal9",
  M: "xamb",
  N: "xan0",
  O: "xbo1",
  P: "xbp2",
  Q: "xcq3",
  R: "xcr4",
  S: "xds5",
  T: "xdt6",
  U: "xeu7",
  V: "xev8",
  W: "xfw9",
  X: "xfxa",
  Y: "xfyb",
  Z: "xfzc",
  a: "x0a1",
  b: "x0b2",
  c: "x0c3",
  d: "x0d4",
  e: "x0e5",
  f: "x0f6",
  g: "x0g7",
  h: "x0h8",
  i: "x0i9",
  j: "x0ja",
  k: "x0kb",
  l: "x0lc",
  m: "x0md",
  n: "x0ne",
  o: "x0of",
  p: "x0pg",
  q: "x0qh",
  r: "x0ri",
  s: "x0sj",
  t: "x0tk",
  u: "x0ul",
  v: "x0vm",
  w: "x0wn",
  x: "x0xo",
  y: "x0yp",
  z: "x0zq",
  "_": "j382",
  "-": "kOz1",
  '1': 'vf1',
  '2': 'y90',
  '3': 'z1a',
  '4': 'a2b',
  '5': 'b3c',
  '6': 'c4d',
  '7': 'd5e',
  '8': 'e6f',
  '9': 'f7g',
  '0': 'g8h',
}

const valueKeyMap: Record<string, string> = {
  "x6a8": "A",
  "x6b9": "B",
  "x7c0": "C",
  "x7d1": "D",
  "x8e2": "E",
  "x8f3": "F",
  "x9g4": "G",
  "x9h5": "H",
  "xai6": "I",
  "xaj7": "J",
  "xak8": "K",
  "xal9": "L",
  "xamb": "M",
  "xan0": "N",
  "xbo1": "O",
  "xbp2": "P",
  "xcq3": "Q",
  "xcr4": "R",
  "xds5": "S",
  "xdt6": "T",
  "xeu7": "U",
  "xev8": "V",
  "xfw9": "W",
  "xfxa": "X",
  "xfyb": "Y",
  "xfzc": "Z",
  "x0a1": "a",
  "x0b2": "b",
  "x0c3": "c",
  "x0d4": "d",
  "x0e5": "e",
  "x0f6": "f",
  "x0g7": "g",
  "x0h8": "h",
  "x0i9": "i",
  "x0ja": "j",
  "x0kb": "k",
  "x0lc": "l",
  "x0md": "m",
  "x0ne": "n",
  "x0of": "o",
  "x0pg": "p",
  "x0qh": "q",
  "x0ri": "r",
  "x0sj": "s",
  "x0tk": "t",
  "x0ul": "u",
  "x0vm": "v",
  "x0wn": "w",
  "x0xo": "x",
  "x0yp": "y",
  "x0zq": "z",
  "j382": "_",
  "kOz1": "-",

  'vf1': '1',
  'y90': '2',
  'z1a': '3',
  'a2b': '4',
  'b3c': '5',
  'c4d': '6',
  'd5e': '7',
  'e6f': '8',
  'f7g': '9',
  'g8h': '0'
}


export default function useShopFilterEncryption() {
  const encrypt = (text: string) => {
    let result = "";

    text.split('').forEach((char, index) => {
      if (keyValueMap[char]) {
        if (index) {
          result = result + "-" + (keyValueMap[char] || char);
        } else {
          result = (keyValueMap[char] || char);
        }
      }
    })


    return result;
  };

  const decrypt = (text: string) => {
    return text.split('-').map((str) => {
      return valueKeyMap[str] || str;
    }).join('');
  }

  return {
    encrypt,
    decrypt,
  };
}