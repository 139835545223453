import newStyled from '@emotion/styled';
import { Menu, MenuButton } from '@mui/base';
import { AccordionGroup } from '@mui/joy';

export const MenuButtonWrapper = newStyled(MenuButton)`
  font-size: 18px;
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  height: var(--header-height);

  @media only screen and (min-width: 621px) {
    display: none;
  }
`;

export const NavMenuWrapper = newStyled(Menu)`
  z-index: 200;
  overflow:hidden;
  box-shadow: 0 8px 8px var(--text-color-transparency-2);
  border: 1px solid var(--text-color-transparency-2);
  border-radius: 8px;
  color: var(--text-color);

  & ul{
    list-style-type: none;
  }

  @media only screen and (min-width: 621px) {
    display: none;
  }
`;

export const NavMenuContainer = newStyled(AccordionGroup)` 
  min-width: 300px;
  background-color:var(--white-color);
  width: 80vw;
  height: fit-content;
  max-height: 80vh;
  background-color: var(--white-color);
  border-radius: 8px;
  margin-top: 5px;
  margin-right: 8px;
  position: relative;
  overflow-x: scroll;

  @media only screen and (min-width: 621px) {
    display: none;
  }
`;
