import React from "react";
import { MdCall } from "react-icons/md";
import { ContactCard } from "../ContactCard";

export const Email = () => {
  return (
    <ContactCard
      icon={MdCall}
      label="SEND US AN EMAIL"
      contact="info@gdsolar.co.ke"
      iconBackground="linear-gradient(180deg, rgb(4,150,255), rgba(54,127,180))"
    />
  );
};
