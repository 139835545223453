import { Chip, ChipDelete } from "@mui/joy";
import * as React from "react";
import { EmailFormFeedback } from "../../types";

type FormFeedbackProps = {
  feedback?: EmailFormFeedback;
  clearFeedBack: () => void;
};

export const FormFeedback: React.FC<FormFeedbackProps> = ({
  feedback,
  clearFeedBack,
}) => {
  if (!feedback) return null;

  return (
    <Chip
      size="md"
      variant="solid"
      color={feedback.error ? "danger" : "success"}
      endDecorator={<ChipDelete onDelete={clearFeedBack} />}
      style={{ margin: "8px 0" }}
    >
      {feedback.message}
    </Chip>
  );
};
