import React, { FC } from "react";
import greenLogo from "@media/images/logos/green-logo.svg";
import orangeLogo from "@media/images/logos/orange-logo.svg";
import whiteLogo from "@media/images/logos/white-logo.svg";

const map = {
  green: greenLogo,
  orange: orangeLogo,
  white: whiteLogo,
};

export const Logo: FC<{
  height: number;
  variant?: "green" | "orange" | "white";
}> = ({ height, variant = "green" }) => {
  return <img src={map[variant]} style={{ height: `${height}px` }} />;
};
