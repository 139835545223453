import React from "react";
import { Stack, Link } from "@mui/joy";
import instagram from "@media/images/instagram.png";
import facebook from "@media/images/facebook.png";
import twitter from "@media/images/x-twitter.png";
import linkedin from "@media/images/linkedin.png";
import {
  SocialMediaLink,
  SocialMediaLinksTitle,
  SocialMediaLinksWrapper,
} from "./styles";

export const SocialMediaLinks = () => {
  return (
    <SocialMediaLinksWrapper gap={"5px"}>
      <SocialMediaLinksTitle level="h4">
        STAY IN TOUCH ON SOCIAL MEDIA
      </SocialMediaLinksTitle>
      <Stack direction={"row"} gap={"8px"}>
        <Link
          href="https://www.instagram.com/greatdealsolarsolution.ltd?igsh=YXpwZDlsZWxrMGxs"
          target="_blank"
        >
          <SocialMediaLink bgImage={instagram} />
        </Link>
        <Link
          href="https://www.facebook.com/people/GreatDeal-Solar-Solutions-LTD/61555787698548/"
          target="_blank"
        >
          <SocialMediaLink bgImage={facebook} />
        </Link>
        <Link href="https://x.com/gdsolarkenya" target="_blank">
          <SocialMediaLink bgImage={twitter} />
        </Link>
        {/* <Link
          href="https://www.youtube.com/@GreatdealSolarSolutions"
          target="_blank"
        >
          <SocialMediaLink bgImage={linkedin} />
        </Link> */}
      </Stack>
    </SocialMediaLinksWrapper>
  );
};
