import { graphql, useStaticQuery } from "gatsby";
import { PageNavType } from "@types";

export default function useNavigationData() {
  const {
    allDataJson: { nodes },
  } = useStaticQuery(graphql`
  query MyQuery {
    allDataJson {
      nodes {
        pages {
          key
          label
          nav {
            key
            label
            links {
              label
              link
              search {
                filter
              }
            }
          }
        }
      }
    }
  }
`);

  const result = {
    pages: [] as PageNavType[]
  }


  if (Array.isArray(nodes?.[0]?.pages)) {
    result.pages = nodes[0].pages as PageNavType[];
  }

  return result
}
