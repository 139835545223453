import React, { PropsWithChildren } from "react";
import { TabButtonWrapper } from "./styles";

export const TabButton: React.FC<
  PropsWithChildren<{
    setTab: (tab: string) => void;
    selectedTab: string;
    tabKey: string;
  }>
> = ({ setTab, selectedTab, tabKey, children }) => {
  return (
    <TabButtonWrapper
      size="sm"
      onClick={() => setTab(tabKey)}
      variant={selectedTab === tabKey ? "solid" : "outlined"}
    >
      {children}
    </TabButtonWrapper>
  );
};
