import React from "react";
import { Box, Stack, Typography } from "@mui/joy";
import { MdCall, MdEmail } from "react-icons/md";
import { BiSolidMessageEdit } from "react-icons/bi";
import { ContactItemCard } from "./ContactItemCard";
import { GetInTouchWrapper, MessageFormWrapper } from "./styles";
import { MessageForm } from "../MessageForm";
import { SocialMediaLinks } from "./SocialMediaLinks";

export const GetInTouch = () => {
  return (
    <Box className="content-wrapper">
      <GetInTouchWrapper
        gap={{ xs: "20px", lg: "40px" }}
        padding={{ xs: "10px", lg: "50px 100px" }}
        id="get_in_touch"
      >
        <Typography
          level="h3"
          fontSize={{ sx: "1.2rem", md: "1.5rem", lg: "2rem" }}
          textAlign={"center"}
        >
          Get In Touch
        </Typography>
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          gap={{ xs: "50px", md: "20px", lg: "50px" }}
        >
          <Stack
            justifyContent={{ xs: "flex-start", md: "space-between" }}
            gap={{ xs: "10px", sm: "20px", md: "30px", lg: "40px" }}
          >
            <ContactItemCard title="Give Us a call" icon={MdCall}>
              <Typography>0708 559 455</Typography>
            </ContactItemCard>
            <ContactItemCard title="SEND US AN EMAIL" icon={MdEmail}>
              <Typography>info@gdsolar.co.ke</Typography>
              <Typography>sales@gdsolar.co.ke</Typography>
            </ContactItemCard>
            <ContactItemCard title="VISIT OUR SHOP" icon={MdCall}>
              <Typography>2nd floor Rubis house - Highview Plaza</Typography>
              <Typography>at Thindigua along Kiambu Road</Typography>
              <Typography>Nairobi, Kenya</Typography>
            </ContactItemCard>
          </Stack>
          <Box width={{ xs: "100%", md: "400px" }}>
            <ContactItemCard
              title="SEND US A MESSAGE"
              icon={BiSolidMessageEdit}
            >
              <MessageFormWrapper>
                <MessageForm />
              </MessageFormWrapper>
            </ContactItemCard>
          </Box>
        </Stack>
        <SocialMediaLinks />
      </GetInTouchWrapper>
    </Box>
  );
};
