import { extendTheme } from "@mui/joy";

/**
 * Link to theme configuration: https://mui.com/joy-ui/customization/default-theme-viewer/
 */

export const newTheme = extendTheme({
  "fontFamily": {
    body: "Urbanist, sans-serif",
    display: "Urbanist, sans-serif",
  },
  "colorSchemes": {
    "light": {
      "palette": {
        "primary": {
          "50": "#fecfad",
          "100": "#fdc398",
          "200": "#fdb784",
          "300": "#fdab6f",
          "400": "#fd9f5b",
          "500": "#fd9346",
          "600": "#fd8732",
          "700": "#e3792d",
          "800": "#ca6c28",
          "900": "#b15e23",
          "outlinedHoverBg": "var(--joy-palette-primary-50, #E3EFFB)",
          "plainColor": "var(--text-color)",

        },
        "neutral": {
          "50": "#e8e8e8",
          "100": "#d1d1d2",
          "200": "#bababb",
          "300": "#a3a4a5",
          "400": "#8d8d8e",
          "500": "#767678",
          "600": "#5f6061",
          "700": "#48494a",
          "800": "#313234",
          "900": "#1B1C1E"
        },
        "text": {
          "primary": "var(--joy-palette-neutral-900)",
          "secondary": "var(--joy-palette-neutral-800)",
        },
      }
    }
  },
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1240,
    },
  },
});
